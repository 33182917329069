import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import LauraParedesImg from '../recursos/LauraParedesImg.png';
import KarinaArcosImg from '../recursos/KarinaArcosImg.png';
import './articulos.css';

function Articulos() {
  return (
    <div className="accordion" id="accordionExample">
      <div className="accordion-item">
        <h2 className="accordion-header">
          <button
            className="accordion-button"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapseOne"
            aria-expanded="true"
            aria-controls="collapseOne"
          >
            LA ALIMENTACIÓN EN EL ADULTO MAYOR
          </button>
        </h2>
        <div
          id="collapseOne"
          className="accordion-collapse collapse show"
          data-bs-parent="#accordionExample"
        >
          <div className="accordion-body">
            <div className="image-container">
              <img
                src={LauraParedesImg}
                alt="Laura Paredes"
                className="article-image"
              />
            </div>
            <p>Lau (Laura Paredes) 58 años</p>
            <p>Docente Jubilada</p>           
                <strong>LA ALIMENTACIÓN EN EL ADULTO MAYOR</strong> <br></br>
                Llegar a la edad del adulto mayor trae consigo una serie de expectativas, inquietudes y muchas veces una angustia en esta fase desconocida.
                En cada etapa el ser humano debe aprender a vivir con felicidad y armonía, pero en algunas ocasiones el estar sometido a stress y situaciones adversas debilitan el sistema auto inmune.
                Por ello es importante recalcar el papel de la alimentación y cuidados en el adulto mayor.
                Al avanzar en edad se van perdiendo nutrientes importantes muchas veces por una inadecuada ingestión de alimentos llegando a desarrollar enfermedades como anemia, estreñimiento, obesidad, fatiga muscular etc. originadas en la gran mayoría por una incorrecta alimentación.
                Una buena ingesta de alimentos por consiguiente ocupa un pilar fundamental en esta etapa. Nos preguntaremos: ¿Cómo debe ser esta alimentación?
                En primer lugar brindarles un espacio cálido con luz natural rodeado del cariño de su familia e integrarlo con paciencia y amor en la mesa siempre y cuando sus posibilidades de movilidad lo permitan; caso contrario adecuar un área en el dormitorio considerando facilitar los aspectos mencionados anteriormente y que ese momento sea provechoso.
                Los adultos mayores son los niños nuevamente de nuestra familia y su primera impresión de alimentos ingresan por sus ojos por ello es conveniente anticiparlo con tiempo para  prepararlo a servirse los alimentos.
                Tocará al cuidador o familiar adecuar una bandeja con pocillos, servilleta, cubiertos sirviendo los alimentos variados y con muchos colores proporcionados por las legumbres y frutas.
                Una buena dieta será a base de proteínas, vegetales, frutas y hortalizas, frutos secos, leche descremada, evitando el consumo de azúcares, carbohidratos y grasas.
                En los mercados locales existen una variedad de productos que poseen alto contenido energético y reemplazan a aquellos que su consumo no es apropiado pudiendo mencionar: quinua, machica, cebada, harina de arroz con alto contenido de nutrientes favoreciendo la digestión en ellos, recordando ingerir porciones de fibra e hidratándose en el día. 
                Una buena hidratación ayudará a humectar la piel y a un buen funcionamiento de los órganos.
                Lograr que cada día de esta etapa sea de provecho, armonía y felicidad. Para terminar les dejo este pensamiento hermoso.
                “La vejez es la edad del atardecer, pero hay atardeceres que todos se paran  a mirar”. 
          </div>
        </div>
      </div>
      <div className="accordion-item">
        <h2 className="accordion-header">
          <button
            className="accordion-button collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapseTwo"
            aria-expanded="false"
            aria-controls="collapseTwo"
          >
            BENEFICIOS DE TENER A MASCOTAS EN LA EDAD ADULTA
          </button>
        </h2>
        <div
          id="collapseTwo"
          className="accordion-collapse collapse"
          data-bs-parent="#accordionExample"
        >
          <div className="accordion-body">
            <div className="image-container">
              <img
                src={KarinaArcosImg}
                alt="Karina Arcos"
                className="article-image"
              />
            </div>
            <p>Karina Arcos Cardenas 44 años</p>
            <p>Docente</p> 
                <strong>BENEFICIOS DE TENER A MASCOSTAS EN LA EDAD ADULTA</strong> 
                <br />
                Sin duda alguna las mascotas mejoran la salud mental y física del adulto mayor. Preocuparse por su pequeño amigo, sacarlo a pasear, jugar o simplemente alimentarlos hace que las personas se sientan activas, no se sientan sedentarias, solitarias, aisladas dentro de su propio hogar.
                Son muchos los beneficios de tener una mascota para toda la familia y más específicamente para personas mayores. De hecho, cada vez son más las familias y los expertos en el tema que recomiendan por terapia tener una mascota en un hogar donde exista un adulto mayor. 
                La presencia de estos animalitos sirve como una excelente terapia ya que la idea es crear un vínculo afectivo entre el animal y el adulto mayor para comenzar el proceso reinserción a la sociedad a que refiero con este término es a volver a sentirse una persona útil y necesaria. 
                Por tanto, no es de extrañar que más familias apuesten y decidan adoptar a un compañero de cuatro patas para favorecer el bienestar y calidad de vida del adulto mayor.
                Tener una mascota es una gran ayuda pues los hace centrarse en otro ser, a dejar de pensar tanto en sí mismos y sus problemas, para enfocarse y dedicarle gran parte del tiempo a su mascota, llevándolos a desarrollar otras actividades como los paseos diarios, idas al veterinario, baños, comida, 
                podemos estar seguros que una mascota traerá mucho bienestar, sensación de utilidad a nuestro adulto mayor entre otras tareas. Por último, ayudan a superar los duelos esto lo pude ver manera personal y a mejorar las relaciones interpersonales, generando lazos con dueños de otras mascotas.
          </div>
        </div>
      </div>
    </div>
  );
}

export default Articulos;
