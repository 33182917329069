import React from "react";
import "./faq.css"
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";

function Faq() {
  return (
    <div class="accordion accordion-flush acc2" id="accordionExample">
      <div class="accordion-item aci2">
        <h2 class="accordion-header">
          <button
            class="accordion-button acb2"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapseOne"
            aria-expanded="true"
            aria-controls="collapseOne"
          >
            <strong>¿Qué tipo de proyectos o programas realiza la fundación?</strong>
          </button>
        </h2>
        <div
          id="collapseOne"
          class="accordion-collapse collapse"
          data-bs-parent="#accordionExample"
        >
          <div class="accordion-body">
          Nuestra fundación de ancianos se dedica a ofrecer una variedad de proyectos y programas diseñados 
          para mejorar la calidad de vida de los adultos mayores. Algunos de los programas destacados incluyen:<br />
          <strong>Atención Médica, Actividades Recreativas y Sociales, Voluntariado Intergeneracional.</strong>
          </div>
        </div>
      </div>
      <div class="accordion-item aci2">
        <h2 class="accordion-header">
          <button
            class="accordion-button collapsed acb2"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapseTwo"
            aria-expanded="false"
            aria-controls="collapseTwo"
          >
            <strong>¿Cómo puedo contribuir como voluntario en la fundación?</strong>
          </button>
        </h2>
        <div
          id="collapseTwo"
          class="accordion-collapse collapse"
          data-bs-parent="#accordionExample"
        >
          <div class="accordion-body">
            Puedes pedir información por medio de nuestro formulario que se encuentra
            en la sección "Mas" y luego en "Contacto", llenas el formulario con todos
            tus datos y nos dejas un mensaje para saber como nos quieres ayudar.
            también contamos con el número de WhatsApp al inicio de nuestra pagina
            en la sección "Nosotros".
          </div>
        </div>
      </div>
      <div class="accordion-item aci2">
        <h2 class="accordion-header">
          <button
            class="accordion-button collapsed acb2"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapseThree"
            aria-expanded="false"
            aria-controls="collapseThree"
          >
            <strong>¿Cuáles son los criterios para ser beneficiario/a de los programas de la fundación?</strong>
          </button>
        </h2>
        <div
          id="collapseThree"
          class="accordion-collapse collapse"
          data-bs-parent="#accordionExample"
        >
          <div class="accordion-body">
          Los criterios para ser beneficiario de los programas de nuestra fundación de ancianos se basan en varios factores 
          destinados a garantizar el apoyo y la atención adecuada para nuestros destinatarios. Estos criterios incluyen la edad, 
          con un enfoque en personas de la tercera edad; la necesidad demostrada de asistencia, que puede incluir limitaciones físicas, 
          dificultades económicas o falta de apoyo familiar; y el compromiso de promover el bienestar y la participación activa en las 
          actividades y servicios que ofrece la fundación. Nuestro objetivo es brindar una red de apoyo integral a aquellos que más lo necesitan, 
          creando así un entorno en el que puedan enriquecer sus vidas y envejecer con dignidad y alegría.
          </div>
        </div>
      </div>
      <div class="accordion-item aci2">
        <h2 class="accordion-header">
          <button
            class="accordion-button collapsed acb2"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapseFour"
            aria-expanded="false"
            aria-controls="collapseFour"
          >
            <strong>¿Cómo puedo apoyar o contribuir a la fundación de formas no monetarias?</strong>
          </button>
        </h2>
        <div
          id="collapseFour"
          class="accordion-collapse collapse"
          data-bs-parent="#accordionExample"
        >
          <div class="accordion-body">
            Puedes apoyar a nuestra fundacion realizando donativos tanto de viveres 
            como materiales de apoyo para las distintas actividades de recreacion,
             asi mismo como insumos medicos para nuestros adultos mayores.
          </div>
        </div>
      </div>
    </div>
  );
}

export default Faq;
