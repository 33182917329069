import React from 'react'


function Footer() {
  return (
    <div className="footer">
      <div className="left-content">
        <h4>Fundación Otoño Inolvidable</h4>
        <p>
          <a href="https://www.google.com.ec/maps/place/FUNDACION+OTO%C3%91O+INOLVIDABLE/@-2.2189509,-79.8987173,17z/data=!3m1!4b1!4m6!3m5!1s0x902d6fd5f34833d7:0xe3d611cd16f0c686!8m2!3d-2.2189563!4d-79.8961424!16s%2Fg%2F11rtq3tdrj?entry=ttu" target="_blank" rel="noopener noreferrer">
          O'Connors 907 y Washington, Barrio del Seguro, Guayaquil, Ecuador
          </a>
        </p>
      </div>
      <div className="centered-content">
        <p>Copyright © 2023 Fundación Otoño Inolvidable - Todos los derechos reservados.</p>
      </div>
      <div className="right-content">
        <h4>Horario</h4>
        <p>Lunes a Jueves de 08:00am a 06:00pm<br />
          Sábados de 09:00am a 12:00pm</p>
      </div>
    </div>
  );
}

export default Footer;
