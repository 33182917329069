import $ from "jquery";

function generarClaveAleatoria(longitud) {
  const caracteres ="ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let clave = "";

  for (let i = 0; i < longitud; i++) {
    const indice = Math.floor(Math.random() * caracteres.length);
    clave += caracteres.charAt(indice);
  }

  return clave;
}

export function producto1() {
  var parametros = {
    amount: "500",
    amountWithoutTax: "500",
    clientTransactionID: generarClaveAleatoria(20),
    responseUrl: "https://otonoinolvidable.org/respuesta",
    cancellationUrl: "https://otonoinolvidable.org/nosotros",
  };
  $.ajax({
    data: parametros,
    url: "https://pay.payphonetodoesposible.com/api/button/Prepare",
    type: "POST",
    beforeSend: function (xhr) {
      xhr.setRequestHeader(
        "Authorization",
        "Bearer wSAc773O1CqKhtk0N0pTZ0977-F7B2nyyf44_ZQtnKCm2xY7JSl2PKhkG-UjeYTdMU3eprljiVbHi34s-Lg29WX0rW9tm7XVFYiMgxXSczEqOnpa_gP1bxEIO4qu8lyRAwuOSwGWYQO_RInQQLr1IViz0WsKTQH1X5MTQojSS9HSL7yvMxOffeknhJT5q5N5BrVV_JfRHPsxzQQ2_0Lgs6nzWV6HVXJfL98iMXYaYH7rKtGjL5otxt3jDcpGtaVg2RKExu3LaeSkBLY3hZOSoPwiFry0pqkO0PUQXT5lkVXEcQ2E27q3SbytOkt0WfHeGPzVwt7HC_jDrJNbUNbSrAy2W_k"
      );
    },
    success: function SolicitarPago(respuesta) {
      window.location.href = respuesta.payWithCard;
    },
    error: function (mensajeerror) {
      alert("Error en la llamada:" + mensajeerror.Message);
    },
  });
}

export function producto2() {
  var parametros = {
    amount: "1000",
    amountWithoutTax: "1000",
    clientTransactionID: generarClaveAleatoria(20),
    responseUrl: "https://otonoinolvidable.org/respuesta",
    cancellationUrl: "https://otonoinolvidable.org/nosotros",
  };
  $.ajax({
    data: parametros,
    url: "https://pay.payphonetodoesposible.com/api/button/Prepare",
    type: "POST",
    beforeSend: function (xhr) {
      xhr.setRequestHeader(
        "Authorization",
        "Bearer wSAc773O1CqKhtk0N0pTZ0977-F7B2nyyf44_ZQtnKCm2xY7JSl2PKhkG-UjeYTdMU3eprljiVbHi34s-Lg29WX0rW9tm7XVFYiMgxXSczEqOnpa_gP1bxEIO4qu8lyRAwuOSwGWYQO_RInQQLr1IViz0WsKTQH1X5MTQojSS9HSL7yvMxOffeknhJT5q5N5BrVV_JfRHPsxzQQ2_0Lgs6nzWV6HVXJfL98iMXYaYH7rKtGjL5otxt3jDcpGtaVg2RKExu3LaeSkBLY3hZOSoPwiFry0pqkO0PUQXT5lkVXEcQ2E27q3SbytOkt0WfHeGPzVwt7HC_jDrJNbUNbSrAy2W_k"
      );
    },
    success: function SolicitarPago(respuesta) {
      window.location.href = respuesta.payWithCard;
    },
    error: function (mensajeerror) {
      alert("Error en la llamada:" + mensajeerror.Message);
    },
  });
}

export function producto3() {
  var parametros = {
    amount: "2000",
    amountWithoutTax: "2000",
    clientTransactionID: generarClaveAleatoria(20),
    responseUrl: "https://otonoinolvidable.org/respuesta",
    cancellationUrl: "https://otonoinolvidable.org/nosotros",
  };
  $.ajax({
    data: parametros,
    url: "https://pay.payphonetodoesposible.com/api/button/Prepare",
    type: "POST",
    beforeSend: function (xhr) {
      xhr.setRequestHeader(
        "Authorization",
        "Bearer wSAc773O1CqKhtk0N0pTZ0977-F7B2nyyf44_ZQtnKCm2xY7JSl2PKhkG-UjeYTdMU3eprljiVbHi34s-Lg29WX0rW9tm7XVFYiMgxXSczEqOnpa_gP1bxEIO4qu8lyRAwuOSwGWYQO_RInQQLr1IViz0WsKTQH1X5MTQojSS9HSL7yvMxOffeknhJT5q5N5BrVV_JfRHPsxzQQ2_0Lgs6nzWV6HVXJfL98iMXYaYH7rKtGjL5otxt3jDcpGtaVg2RKExu3LaeSkBLY3hZOSoPwiFry0pqkO0PUQXT5lkVXEcQ2E27q3SbytOkt0WfHeGPzVwt7HC_jDrJNbUNbSrAy2W_k"
      );
    },
    success: function SolicitarPago(respuesta) {
      window.location.href = respuesta.payWithCard;
    },
    error: function (mensajeerror) {
      alert("Error en la llamada:" + mensajeerror.Message);
    },
  });
}

export function producto4() {
  var parametros = {
    amount: "3000",
    amountWithoutTax: "3000",
    clientTransactionID: generarClaveAleatoria(20),
    responseUrl: "https://otonoinolvidable.org/respuesta",
    cancellationUrl: "https://otonoinolvidable.org/nosotros",
  };
  $.ajax({
    data: parametros,
    url: "https://pay.payphonetodoesposible.com/api/button/Prepare",
    type: "POST",
    beforeSend: function (xhr) {
      xhr.setRequestHeader(
        "Authorization",
        "Bearer wSAc773O1CqKhtk0N0pTZ0977-F7B2nyyf44_ZQtnKCm2xY7JSl2PKhkG-UjeYTdMU3eprljiVbHi34s-Lg29WX0rW9tm7XVFYiMgxXSczEqOnpa_gP1bxEIO4qu8lyRAwuOSwGWYQO_RInQQLr1IViz0WsKTQH1X5MTQojSS9HSL7yvMxOffeknhJT5q5N5BrVV_JfRHPsxzQQ2_0Lgs6nzWV6HVXJfL98iMXYaYH7rKtGjL5otxt3jDcpGtaVg2RKExu3LaeSkBLY3hZOSoPwiFry0pqkO0PUQXT5lkVXEcQ2E27q3SbytOkt0WfHeGPzVwt7HC_jDrJNbUNbSrAy2W_k"
      );
    },
    success: function SolicitarPago(respuesta) {
      window.location.href = respuesta.payWithCard;
    },
    error: function (mensajeerror) {
      alert("Error en la llamada:" + mensajeerror.Message);
    },
  });
}

export function producto5() {
  var parametros = {
    amount: "5000",
    amountWithoutTax: "5000",
    clientTransactionID: generarClaveAleatoria(20),
    responseUrl: "https://otonoinolvidable.org/respuesta",
    cancellationUrl: "https://otonoinolvidable.org/nosotros",
  };
  $.ajax({
    data: parametros,
    url: "https://pay.payphonetodoesposible.com/api/button/Prepare",
    type: "POST",
    beforeSend: function (xhr) {
      xhr.setRequestHeader(
        "Authorization",
        "Bearer wSAc773O1CqKhtk0N0pTZ0977-F7B2nyyf44_ZQtnKCm2xY7JSl2PKhkG-UjeYTdMU3eprljiVbHi34s-Lg29WX0rW9tm7XVFYiMgxXSczEqOnpa_gP1bxEIO4qu8lyRAwuOSwGWYQO_RInQQLr1IViz0WsKTQH1X5MTQojSS9HSL7yvMxOffeknhJT5q5N5BrVV_JfRHPsxzQQ2_0Lgs6nzWV6HVXJfL98iMXYaYH7rKtGjL5otxt3jDcpGtaVg2RKExu3LaeSkBLY3hZOSoPwiFry0pqkO0PUQXT5lkVXEcQ2E27q3SbytOkt0WfHeGPzVwt7HC_jDrJNbUNbSrAy2W_k"
      );
    },
    success: function SolicitarPago(respuesta) {
      window.location.href = respuesta.payWithCard;
    },
    error: function (mensajeerror) {
      alert("Error en la llamada:" + mensajeerror.Message);
    },
  });
}

export function producto6() {
  var parametros = {
    amount: "10000",
    amountWithoutTax: "10000",
    clientTransactionID: generarClaveAleatoria(20),
    responseUrl: "https://otonoinolvidable.org/respuesta",
    cancellationUrl: "https://otonoinolvidable.org/nosotros",
  };
  $.ajax({
    data: parametros,
    url: "https://pay.payphonetodoesposible.com/api/button/Prepare",
    type: "POST",
    beforeSend: function (xhr) {
      xhr.setRequestHeader(
        "Authorization",
        "Bearer wSAc773O1CqKhtk0N0pTZ0977-F7B2nyyf44_ZQtnKCm2xY7JSl2PKhkG-UjeYTdMU3eprljiVbHi34s-Lg29WX0rW9tm7XVFYiMgxXSczEqOnpa_gP1bxEIO4qu8lyRAwuOSwGWYQO_RInQQLr1IViz0WsKTQH1X5MTQojSS9HSL7yvMxOffeknhJT5q5N5BrVV_JfRHPsxzQQ2_0Lgs6nzWV6HVXJfL98iMXYaYH7rKtGjL5otxt3jDcpGtaVg2RKExu3LaeSkBLY3hZOSoPwiFry0pqkO0PUQXT5lkVXEcQ2E27q3SbytOkt0WfHeGPzVwt7HC_jDrJNbUNbSrAy2W_k"
      );
    },
    success: function SolicitarPago(respuesta) {
      window.location.href = respuesta.payWithCard;
    },
    error: function (mensajeerror) {
      alert("Error en la llamada:" + mensajeerror.Message);
    },
  });
}
