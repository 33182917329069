import React from 'react';
import header2 from '../recursos/header2.png'; 
import "./header4.css"

function Header4() {
  return (
    <header className="header4">
      <div className="image-container">
        <img
          src={header2}
          alt="Header Image"
          className="header-image"
        />
        <div className="overlay4"></div>
      </div>
      <div className="content-header4">
        <p className="text-header4">¡Revisa nuestros videos o visita nuestro canal de Yotube! <a href="https://www.youtube.com/@otonotvoficial6869"  className="btn btn-outline-warning" target="_blank" >@OtoñoTvOficial</a></p>
      </div>
    </header>
  );
}

export default Header4;
