import "./App.css";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import Navbar from "./components/navbar";
import Header from "./components/header";
import Body from "./components/body";
import Footer from "./components/footer";
import Servicios from "./components/servicios";
import Madl50 from "./components/madl50";
import Faq from "./components/faq";
import Articulos from "./components/articulos";
import Contacto from "./components/contacto";
import Donaciones from "./components/donaciones";
import Header2 from "./components/header2";
import Header3 from "./components/header3";
import Header4 from "./components/header4";
import CarruselRecreativa from "./components/carruselRecreativa";
import CarruselComedor from "./components/carruselComedor";
import CarruselBailoterapia from "./components/carruselBailoterapia";
import CarruselServicioMedico from "./components/carruselServicioMedico";
import Respuesta from './utils/respuesta';


function Main(){
  return(
    <>
      <Navbar />
      <Header />
      <Body />
      <Footer />
    </>
  )
}

function App() {
  return (
    <>
      <Router>
        <Routes>
          {/* Redirigir automáticamente a /nosotros */}
          <Route exact path="/" element={<Main />} />
          <Route
            exact path="/nosotros"
            element={
                <Main />
            }
          />
          <Route
            path="/servicios"
            element={
              <>
                <Navbar />
                <Header3 />
                <Servicios />
                <Footer />
              </>
            }
          />
          <Route
            path="/donaciones"
            element={
              <>
                <Navbar />
                <Donaciones />
                <Footer />
              </>
            }
          />
          <Route
            path="/articulos"
            element={
              <>
                <Navbar />
                <Articulos />
                <Footer />
              </>
            }
          />
          <Route
            path="/contacto"
            element={
              <>
                <Navbar />
                <Header2 />
                <Contacto />
                <Footer />
              </>
            }
          />
          <Route
            path="/galeriaRecreativa"
            element={
              <>
              <Navbar />
              <Header3 />
              <CarruselRecreativa />
              <Footer />
              </>
            }
          />
          <Route
            path="/galeriaComedor"
            element={
              <>
              <Navbar />
              <Header3 />
              <CarruselComedor />
              <Footer />
              </>
            }
          />
          <Route
            path="/galeriaBailoterapia"
            element={
              <>
              <Navbar />
              <Header3 />
              <CarruselBailoterapia />
              <Footer />
              </>
            }
          />
          <Route
            path="/galeriaServicioMedico"
            element={
              <>
              <Navbar />
              <Header3 />
              <CarruselServicioMedico />
              <Footer />
              </>
            }
          />
          <Route
            path="/madl50"
            element={
              <>
                <Navbar />
                <Header4 />
                <Madl50 />
                <Footer />
              </>
            }
          />
          <Route
            path="/faq"
            element={
              <>
                <Navbar />
                <Faq />
                <Footer />
              </>
            }
          />
          <Route
            path="/respuesta"
            element={
              <>
                <Navbar />
                <Respuesta />
                <Footer />
              </>
            }
          />
        </Routes>
      </Router>
    </>
  );
}

export default App;
