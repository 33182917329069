import React from "react";

export function openWhatsApp() {
  const phoneNumber = "+593969671140"; 
  const message = "Hola, quiero saber como puedo ayudar a la fundacion";
  const encodedMessage = encodeURIComponent(message);
  const whatsappURL = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;

  window.open(whatsappURL, "_blank");
}

function WhatsAppButton() {
  return (
    <a className="text2" onClick={openWhatsApp}>
      ¡Contactanos 593 (969)671-140! 
    </a>
  );
}

export default WhatsAppButton;
