import React from "react";
import "./servicios.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import bailoterapia from "../recursos/bailoterapia.png";
import comedor from "../recursos/comedor.png";
import serviciomedicoImg from "../recursos/serviciomedicoImg.png";
import actRecreativa from "../recursos/actRecreativa.jpeg"

function Servicios() {
  return (
    <>
      <div className="card-container">
        <div className="card" style={{ width: "25rem" }}>
          <img src={bailoterapia} className="card-img-top" alt="" />
          <p className="card-tittle"><br />
            <strong>Bailoterapia</strong>
          </p>
          <div className="card-body">
            <p className="card-text">
              Ayudamos a Nuestros adultos mayores con actividades recreativas
              como bailoterapias.
            </p><br />
            <a href="/galeriaBailoterapia" className="btn btn-warning">
              Galeria
            </a>
          </div>
        </div>
        <div className="card" style={{ width: "25rem" }}>
          <img src={comedor} className="card-img-top" alt="" />
          <p className="card-tittle"><br />
            <strong>Comedor Comunitario</strong>
          </p>
          <div className="card-body">
            <p className="card-text">
              Nos encargamos de proveerles alimentacion los dias que 
              vienen a nuestra fundacion.
            </p><br />
            <a href="galeriaComedor" className="btn btn-warning">
              Galeria
            </a>
          </div>
        </div>
        <div className="card" style={{ width: "25rem" }}>
          <img src={serviciomedicoImg} className="card-img-top" alt="" />
          <p className="card-tittle"><br />
            <strong>Servicio Medico</strong>
          </p>
          <div className="card-body">
            <p className="card-text">
              Tambien nos enfocamos en mantenerlos saludables brindandoles
              atencion medica gratuita.
            </p>
            <a href="/galeriaServicioMedico" className="btn btn-warning">
              Galeria
            </a>
          </div>
        </div>
        <div className="card" style={{ width: "25rem" }}>
          <img src={actRecreativa} className="card-img-top" alt="" />
          <p className="card-tittle"><br />
            <strong>Actividades Recreativas</strong>
          </p>
          <div className="card-body">
            <p className="card-text">
              Mantenemos a nuestros adultos mayores en actividades de recreación y se 
              mantengan entretenidos.
            </p>
            <a href="/galeriaRecreativa" className="btn btn-warning">
              Galeria
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

export default Servicios;
