import React, { useEffect } from 'react'
import { Link } from 'react-router-dom';
import './respuesta.css';

function Respuesta() {
    
    useEffect(() => {
        MetodoPOST(); // Llama a la función MetodoGET cuando se monte el componente
      }, []);

    return (
        <>
        <div className="containerR">
            <div className="topboxR">
                <p><strong>¡Gracias por tu donacion!</strong></p>
            </div>
            <div className="middleboxR">
                <p>
                Tu generosidad ilumina nuestro camino y hace posible que nuestras metas y sueños sigan tomando forma. 
                Cada contribución que compartes con nosotros es un paso hacia adelante en la misión de crear un impacto positivo en la comunidad.
                 Gracias por ser parte de este viaje hacia un futuro mejor. Tu donación es más que un regalo; es un puente que conecta esperanzas y realidades.
                </p>
                <br />
            </div>
            <div>
                    <Link to="/nosotros"> {/* Enlace a la página "Nosotros" */}
                        <button className="btn btn-outline-dark">Regresar al Inicio</button>
                    </Link>
                </div>
        </div>
        </>
    );
}

export default Respuesta


function MetodoPOST() {
// Crear un objeto de configuración para la solicitud
const urlParams = new URLSearchParams(window.location.search);
const transaccion = urlParams.get("id");
const client = urlParams.get("clientTransactionId");

const requestOptions = {
    method: 'POST',
    headers: {
      'Authorization': 'Bearer wSAc773O1CqKhtk0N0pTZ0977-F7B2nyyf44_ZQtnKCm2xY7JSl2PKhkG-UjeYTdMU3eprljiVbHi34s-Lg29WX0rW9tm7XVFYiMgxXSczEqOnpa_gP1bxEIO4qu8lyRAwuOSwGWYQO_RInQQLr1IViz0WsKTQH1X5MTQojSS9HSL7yvMxOffeknhJT5q5N5BrVV_JfRHPsxzQQ2_0Lgs6nzWV6HVXJfL98iMXYaYH7rKtGjL5otxt3jDcpGtaVg2RKExu3LaeSkBLY3hZOSoPwiFry0pqkO0PUQXT5lkVXEcQ2E27q3SbytOkt0WfHeGPzVwt7HC_jDrJNbUNbSrAy2W_k',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      id: transaccion, 
      clientTxId: client 
    })
  };
  
  // Realizar la solicitud a la API
  fetch("https://pay.payphonetodoesposible.com/api/button/V2/Confirm", requestOptions)
    .then(response => response.json())
    .then(data => {
      // Hacer algo con la respuesta, si es necesario
      // Por ejemplo, si la respuesta tiene un estado "completed", podrías redirigir
      if (data && data.status === "completed") {
        console.log(data)
      } else {
        alert("Hubo un problema con el pago. Por favor, intenta nuevamente o comunicate con tu banco.");
      }
    })
    .catch(error => {
      alert("Error en la solicitud:", error);
    });
}