import React from "react";
import WhatsAppButton from "./WhatsAppButton";
import videofoi from '../recursos/videofoi.mp4'
import foifb from '../recursos/foifb.png'
import foiig from '../recursos/foiig.png'
import foiyt from '../recursos/foiyt.png'
import foitiktok from '../recursos/foitiktok.png'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';


function Header() {
  return (
    <header className="header">
      <div className="video-container">
          <video autoPlay muted loop className="video">
            <source src={videofoi} type="video/mp4" />
          </video>
        <div className="overlay"></div>
      </div>
      <div className="content">
        <p className="text">Somos especialistas en brindar servicio de ayuda social en el Ecuador</p>
        <div>
          <WhatsAppButton />
      </div>
      </div>
      <div className="social-icons">
        <a href="https://www.instagram.com/_fundacionfoi/" target="_blank" rel="noreferrer">
          <img src={foiig} alt="Instagram" />
        </a>
        <a href="https://www.facebook.com/FundacionFOI" target="_blank " rel="noreferrer">
          <img src={foifb} alt="Facebook" />
        </a>
        <a href="https://www.youtube.com/@otonotvoficial6869" target="_blank" rel="noreferrer">
          <img src={foiyt} alt="Youtube" />
        </a>
        <a href="https://www.tiktok.com/@otono_tv" target="_blank" rel="noreferrer">
          <img src={foitiktok} alt="tiktok" />
        </a>
      </div>
    </header>
  );
}

export default Header;