import React from "react";
import grupofoi from '../recursos/grupofoi.png';

function Body() {
    return (
      <><div className="body">
            <div className="left-content-image">
                <img
                    src={grupofoi}
                    alt="GrupoFOI"
                    className="image" />
            </div>
            <div className="right-content-nosotros">
                <h2 className="H2">NOSOTROS</h2>
                <p className="paragraph">Somos expertos en Fundación Otoño Inolvidable y nuestra calidad y experiencia nos han llevado a posicionarnos como líderes desde 2014.
                    Trabajamos siempre con la convicción de entregar los mejores resultados, puedes estar seguro que contigo no será la excepción.
                    Convierte a Fundación Otoño Inolvidable en tu nuevo aliado.</p>
            </div>
        </div>
        <div className="body2">
            <div className="additional-paragraph1">
                <h3 className="H31">Misión</h3>
                <p>Mejorar la calidad de vida de personas adultas mayores, posibilitando la relación y colaboración 
                    en actividades recreo deportivo y cultural de carácter local 
                    y nacional, mejorando su calidad de vida, en busca del buen vivir.</p>
            </div>
            <div className="additional-paragraph2">
                <h3 className="H32">Visión</h3>
                <p>Ser reconocidos como una entidad social sin fines de lucro innovadora abierta al entorno, 
                    cuyo propósito esté basado 
                    en la calidad del servicio y en la incorporación social de las personas de la tercera edad.</p>
            </div>
        </div>
        <div className="body3">
            <div className="centered-paragraph">
                <h3 className="H33">Valores</h3>
                <ul className="valores">
                    <li>Socialidad</li>
                    <li>Solidaridad</li>
                    <li>Compromiso</li>
                    <li>Responsabilidad</li>
                </ul>

            </div>
        </div>
        </>
    );
  }
  
  export default Body;
  