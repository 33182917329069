import React from 'react';
import WhatsAppButton from './WhatsAppButton'; // Import the WhatsAppButton component and replace './WhatsAppButton' with the correct path
import header2 from '../recursos/header2.png'; // Import the image and replace '../headerImage.png' with the correct path
import "./header2.css"

function Header2() {
  return (
    <header className="header2">
      <div className="image-container">
        <img
          src={header2}
          alt="Header Image"
          className="header-image"
        />
        <div className="overlay2"></div>
      </div>
      <div className="content-header2">
        <p className="text-header2">O escribenos directamente a nuestro número: <WhatsAppButton/></p>
      </div>
    </header>
  );
}

export default Header2;
