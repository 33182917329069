import React from 'react'
import { useNavigate } from 'react-router-dom'; 
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import "./carrusel.css"


function CarruselBailoterapia() {
  const navigate  = useNavigate(); 

  const handleGoBack = () => {
    navigate('/nosotros'); 
  };
    return (
      <>
      <div id="carouselExampleIndicators" className="carousel slide carousel-dark slide">
        <div className="carousel-indicators">
          <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
          <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
          <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
          <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="3" aria-label="Slide 3"></button>
          <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="4" aria-label="Slide 4"></button>
          <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="5" aria-label="Slide 5"></button>
        </div>
        <div className="carousel-inner">
          <div className="carousel-item active">
            <div className="video-container">
            <iframe width="850" height="480" src="https://www.youtube.com/embed/5kI_ZPus3Fg?list=PLDOBrzW1GqbHjsLmtuMzSMDA9E8YYJ_g1" title="Tiempo Libre. Más allá de los cincuenta" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
          </div>
          <div className="carousel-item">
          <div className="video-container">
          <iframe width="850" height="480" src="https://www.youtube.com/embed/wKB4dw8V-tI?list=PLDOBrzW1GqbHjsLmtuMzSMDA9E8YYJ_g1" title="Plaza Samanes" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
          </div>
          <div className="carousel-item">
            <div className="video-container">
            <iframe width="850" height="480" src="https://www.youtube.com/embed/hHsiUX9uk_A?list=PLDOBrzW1GqbHjsLmtuMzSMDA9E8YYJ_g1" title="Deleites de mi Tierra." frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
          </div>
          <div className="carousel-item">
            <div className="video-container">
            <iframe width="850" height="480" src="https://www.youtube.com/embed/Xx6xVE7QXeY?list=PLDOBrzW1GqbGT0051ynQp3odjPn-rJvcI" title="Conversando Con. Más Allá de los cincuenta." frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
          </div>
          <div className="carousel-item">
            <div className="video-container">
            <iframe width="850" height="480" src="https://www.youtube.com/embed/Go1Gzx0sdyY?list=PLDOBrzW1GqbGT0051ynQp3odjPn-rJvcI" title="Gloria Ruíz. Promotora Cultural." frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
          </div>
        </div>
        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
          <span className="carousel-control-prev-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
          <span className="carousel-control-next-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Next</span>
        </button>
        <div className="button-container">
          <button onClick={handleGoBack} className="btn btn-outline-secondary">Regresar al inicio</button>
        </div>
      </div>
        </>
    );
  }

export default CarruselBailoterapia