import React from 'react'
import { useNavigate } from 'react-router-dom'; 
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import "./carrusel.css"
import recreativa1 from "../fotosGaleria/fotosRecreativas/recreativa1.jpg";
import recreativa2 from "../fotosGaleria/fotosRecreativas/recreativa2.jpeg";
import recreativa3 from "../fotosGaleria/fotosRecreativas/recreativa3.jpg";
import recreativa4 from "../fotosGaleria/fotosRecreativas/recreativa4.jpeg";
import recreativa5 from "../fotosGaleria/fotosRecreativas/recreativa5.jpeg";
import recreativa6 from "../fotosGaleria/fotosRecreativas/recreativa6.jpeg";

//FALTA CAMBIAR TODAS LAS FOTOS YA QUE SOLO SE AGREGO EL CARRUSEL
function CarruselServicioMedico() {
  const navigate  = useNavigate(); 

  const handleGoBack = () => {
    navigate('/servicios'); 
  };
    return (
      <>
      <div id="carouselExampleIndicators" className="carousel slide carousel-dark slide">
        <div className="carousel-indicators">
          <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
          <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
          <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
          <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="3" aria-label="Slide 3"></button>
          <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="4" aria-label="Slide 4"></button>
          <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="5" aria-label="Slide 5"></button>
          <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="6" aria-label="Slide 6"></button>
          
        </div>
        <div className="carousel-inner">
          <div className="carousel-item active">
            <img src={recreativa1} className="d-block w-100" alt="" />
          </div>
          <div className="carousel-item">
            <img src={recreativa2} className="d-block w-100" alt="Slide 2" />
          </div>
          <div className="carousel-item">
            <img src={recreativa3} className="d-block w-100" alt="Slide 3" />
          </div>
          <div className="carousel-item">
            <img src={recreativa4} className="d-block w-100" alt="Slide 4" />
          </div>
          <div className="carousel-item">
            <img src={recreativa5} className="d-block w-100" alt="Slide 5" />
          </div>
          <div className="carousel-item">
            <img src={recreativa6} className="d-block w-100" alt="Slide 6" />
          </div>
        </div>
        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
          <span className="carousel-control-prev-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
          <span className="carousel-control-next-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Next</span>
        </button>
        <div className="button-container">
          <button onClick={handleGoBack} className="btn btn-outline-secondary">Regresar a servicios</button>
        </div>
      </div>
        </>
    );
  }

export default CarruselServicioMedico