import React from 'react'
import './contacto.css'

const contacto = () => {
  return (
    <div className='form-container'>
      <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSeq6oO-fM-rVaid_XurRVI1FaS-PN4XeVwo_admuA7cRHMDOQ/viewform?embedded=true" width="1920" height="1080" frameborder="0" marginheight="0" marginwidth="0">Cargando…</iframe>
      </div>
  )
}

export default contacto