import React from "react";
import "./donaciones.css";
import paypalLogo from "../recursos/paypalLogo.png";
import payphoneLogo from "../recursos/payphoneLogo.png";
import ctafoi from "../recursos/ctafoi.png";
import {
  producto1,
  producto2,
  producto3,
  producto4,
  producto5,
  producto6,
} from "../utils/ppUtils";

function Donaciones() {
  return (
    <div className="container">
      <div className="top-box">
        <p>
          <strong>¡Ayuda a nuestra causa!</strong>
        </p>
      </div>
      <div className="middle-box">
        <p>
          Tu apoyo y tus contribuciones nos permitirán alcanzar nuestras metas y
          mejorar las condiciones. Tu generosa donación financiará nuestra
          misión. ¡Ayúdanos a ayudar!
        </p>
      </div>
      <div className="pp">
        <img className="pp-image" src={payphoneLogo} />
      </div>
      <div className="bottom-boxes">
        <div className="box-container">
          <button onClick={producto1} className="btn btn-outline-dark">
            $5
          </button>
          <button onClick={producto2} className="btn btn-outline-dark">
            $10
          </button>
          <button onClick={producto3} className="btn btn-outline-dark">
            $20
          </button>
          <button onClick={producto4} className="btn btn-outline-dark">
            $30
          </button>
          <button onClick={producto5} className="btn btn-outline-dark">
            $50
          </button>
          <button onClick={producto6} className="btn btn-outline-dark">
            $100
          </button>
        </div>
      </div>
      <div className="down-box">
        <p>
          <strong>¡O Realiza tu transacción a nuestra cuenta bancaria!</strong>
          <div className="cuentafoi">
            <img className="ctaPA" src={ctafoi} alt="Cuenta bancaria" />
          </div>
        </p>
      </div>
    </div>
  );
}

export default Donaciones;
