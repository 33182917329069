import React, { useState } from "react";
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import foilogoSinFondo from '../recursos/foilogoSinFondo.png'

function Navbar() {
  const [isNavOpen, setIsNavOpen] = useState(false);

  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };

  return (
    <nav className="navbar navbar-expand-lg nav-underline ">
      <div className="container-fluid">
        <Link className="navbar-brand" to="/">
          <img src={foilogoSinFondo} alt="Logo" width="320" height="95" className="d-inline-block align-text-top" />
        </Link>
        <button className="navbar-toggler" type="button" onClick={toggleNav}>
          <span className="navbar-toggler-icon"></span>
        </button>
      </div>
      <div className={`collapse navbar-collapse ${isNavOpen ? 'show' : ''}`} id="navbarNavDropdown">
        <ul className="navbar-nav">
          <li className="nav-item">
            <Link className="nav-link" to="/nosotros">Nosotros</Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/servicios">Servicios/Galeria</Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/donaciones">Donaciones</Link>
          </li>
          <li className="nav-item dropdown">
            <Link className="nav-link dropdown-toggle" to="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              MAS
            </Link>
            <ul className="dropdown-menu">
              <li><Link className="dropdown-item" to="/articulos">Articulos</Link></li>
              <li><Link className="dropdown-item" to="/contacto">Contacto</Link></li>
              <li><Link className="dropdown-item" to="/madl50">MADL50</Link></li>
              <li><Link className="dropdown-item" to="/FAQ">FAQ</Link></li>
            </ul>
          </li>
        </ul>
      </div>
    </nav>
  );
}

export default Navbar;
