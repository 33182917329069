import React from 'react';
import header2 from '../recursos/header2.png'; 
import "./header3.css"

function Header3() {
  return (
    <header className="header3">
      <div className="image-container">
        <img
          src={header2}
          alt="Header Image"
          className="header-image"
        />
        <div className="overlay3"></div>
      </div>
      <div className="content-header3">
        <p className="text-header3">Conoce nuestros servicios y revisa nuestra galeria de fotos</p>
      </div>
    </header>
  );
}

export default Header3;
