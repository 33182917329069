import React from 'react'
import { useNavigate } from 'react-router-dom'; 
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import "./carrusel.css"
import comedor1 from "../fotosGaleria/fotosComedor/comedor1.jpg";
import comedor2 from "../fotosGaleria/fotosComedor/comedor2.png";
import comedor3 from "../fotosGaleria/fotosComedor/comedor3.jpg";
import comedor4 from "../fotosGaleria/fotosComedor/comedor4.jpg";
import comedor5 from "../fotosGaleria/fotosComedor/comedor5.jpg";
import comedor6 from "../fotosGaleria/fotosComedor/comedor6.jpg";


function CarruselComedor() {
  const navigate  = useNavigate(); 

  const handleGoBack = () => {
    navigate('/servicios'); 
  };
    return (
      <>
      <div id="carouselExampleIndicators" className="carousel slide carousel-dark slide">
        <div className="carousel-indicators">
          <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
          <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
          <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
          <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="3" aria-label="Slide 3"></button>
          <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="4" aria-label="Slide 4"></button>
          <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="5" aria-label="Slide 5"></button>          
        </div>
        <div className="carousel-inner">
          <div className="carousel-item active">
            <img src={comedor1} className="d-block w-100" alt="" />
          </div>
          <div className="carousel-item">
            <img src={comedor2} className="d-block w-100" alt="Slide 2" />
          </div>
          <div className="carousel-item">
            <img src={comedor3} className="d-block w-100" alt="Slide 3" />
          </div>
          <div className="carousel-item">
            <img src={comedor4} className="d-block w-100" alt="Slide 4" />
          </div>
          <div className="carousel-item">
            <img src={comedor5} className="d-block w-100" alt="Slide 5" />
          </div>
          <div className="carousel-item">
            <img src={comedor6} className="d-block w-100" alt="Slide 6" />
          </div>
        </div>
        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
          <span className="carousel-control-prev-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
          <span className="carousel-control-next-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Next</span>
        </button>
        <div className="button-container">
          <button onClick={handleGoBack} className="btn btn-outline-secondary">Regresar a servicios</button>
        </div>
      </div>
        </>
    );
  }

export default CarruselComedor